/* src/styles/fonts.css */

/* GmarketSans Bold */
@font-face {
    font-family: 'GmarketSansBold';
    src: url('../assets/fonts/GmarketSansBold.otf') format('opentype');
    font-weight: 500;
  }
  
  /* Pretendard Medium */
  @font-face {
    font-family: 'PretendardMedium';
    src: url('../assets/fonts/Pretendard-Medium.woff') format('woff');
    font-weight: 500;
  }
  